import React, {useEffect, useRef, useState, useLayoutEffect, useMemo} from 'react';
import Plotly from 'plotly.js';
import {useSelector} from 'react-redux';
import styled from 'styled-components';

import {Button, Card, Grid, GridSize, Typography} from '@material-ui/core';
import {Breakpoint} from '@material-ui/core/styles/createBreakpoints';
import {SliceState} from '@common/api/models/attachments/ISliceAttachment';
import {
  AnalysisType2D,
  DefectSeverity,
  DefectType,
  FILTERABLE_DEFECT_TYPES,
} from '@common/api/models/builds/data/defects/IDefect';
import {PartAreas} from '@common/api/models/builds/data/IPart';
import {IDefectSummaryGETResponse} from '@common/api/models/builds/data/defects/IDefectSummary';

import {DefectSummaryTable} from '../../../../components/molecules/Table/DefectsSummaryTable';
import DefectsTable from '../../../../components/molecules/Table/DefectsTable';
import DefectsGraph, {graphData} from './DefectsGraph';

import {ImageOverlays} from '../../../../components/molecules/Viewport/2D/SingleImageViewport';
import {useSliceAttachmentStoreActions} from '../../../../store/actions';
import {RootState} from '../../../../store/reducers';
import DefectViewport from './DefectViewport';
import {LiveBuildDataProps} from '../index';
import {FetchingState} from '../../../../store/model/liveUpdateStore';
import {ViewType} from '../ActiveBuild';
import DefectsGraphOptionsModal from '../../../../../src/components/molecules/Modals/GraphOptionsModal';
import {useExtraSmallScreenSize, useSmallScreenSize} from '../../../../utils/utilHooks';
import {SelectedDefectSummary, useDefectSummaries} from './useDefectSummaries';
import {changeColourBrightness} from '../../../../utils/color';
import {availableDefectTypesGET} from '../../../../api/ajax/defectStatistic';

import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';

export type PartAreaList = {[uuid: string]: PartAreas};

export interface DefectsSummaryRow extends IDefectSummaryGETResponse {
  areaGraph: graphData;
  coverageGraph: graphData;
  partAreaGraph: graphData;
  url?: string;
  tableData?: {
    id?: number;
    checked?: boolean;
  };
}

export interface DefectImageOverlays extends ImageOverlays {
  layerNum: number;
}

export interface ViewportDefect {
  layerNum: number;
  boundingBox: [number, number, number, number]; // (x, y, w, h)
  widthPx: number;
  heightPx: number;
  analysisType: AnalysisType2D;
}

export interface MinMax<T> {
  min?: T;
  max?: T;
}

export interface FilterByParams {
  defectType: DefectType | '';
  partName: string;
  areaRange: MinMax<number>;
  coverageRange: MinMax<number>;
}

export interface DefectStatFilterByParams extends FilterByParams {
  severity: DefectSeverity | '';
  timestamp: MinMax<moment.Moment>;
  layerRange: MinMax<number>;
}

export enum RollingMethod {
  average = 'average',
  median = 'median',
  min = 'min',
  max = 'max',
  minMax = 'Min-Max',
}

export enum LineType {
  solid = 'solid',
  dot = 'dot',
  dash = 'dash',
  dotDash = 'dashdot',
}
export interface SelectedGraphConfig {
  showTrendline: boolean;
  showOriginal: boolean;
  useOverrides: boolean;
  rollingDataConfig: RollingDataConfig;
  dataLineSettings: LineSettingsInfo;
  trendLineSettings: LineSettingsInfo;
  showPartArea?: boolean;
}

export interface GlobalGraphConfig {
  showTrendline: boolean;
  showOriginal: boolean;
  useOverrides?: boolean;
  showPartArea: boolean;
  useCoverage: boolean;
  rollingDataConfig: RollingDataConfig;
}

export interface LineSettingsInfo {
  lineColor: string;
  lineWeight: number;
  lineType: LineType;
}

export interface RollingDataConfig {
  rollingPeriod: number;
  rollingMethod: RollingMethod;
}

type BreakpointGridSizes = Partial<{[key in Breakpoint]: GridSize}>;
type GridSizePair = {
  firstItem: BreakpointGridSizes;
  secondItem: BreakpointGridSizes;
};

interface ViewLayouts {
  horizontal: GridSizePair;
  vertical: GridSizePair;
  grid: GridSizePair;
}

export const defaultFilterValue: FilterByParams = {
  defectType: '',
  partName: '',
  areaRange: {},
  coverageRange: {},
};
export const defaultStatFilterValue: DefectStatFilterByParams = {
  ...defaultFilterValue,
  severity: '',
  timestamp: {},
  layerRange: {},
};

export default function DefectsPage(props: LiveBuildDataProps) {
  /* Slice Attachment Store */
  const sliceAttachmentActions = useSliceAttachmentStoreActions();
  const sliceAttachmentStore = useSelector((state: RootState) => state.sliceAttachmentStore);
  const [filterableDefectTypes, setFilterableDefectTypes] = useState<string[]>([]);

  useEffect(() => {
    async function fetchDefectTypes() {
      const res = await availableDefectTypesGET(props.build.uuid);
      if (res.success) {
        const filterableDefectTypes = res.data.filter((defectType) =>
          FILTERABLE_DEFECT_TYPES.includes(defectType as DefectType)
        );
        setFilterableDefectTypes(filterableDefectTypes);
      }
    }

    fetchDefectTypes();
    sliceAttachmentActions.ensureConsistent({
      buildUuid: props.build.uuid,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.build.uuid]);

  const validSliceAttachments = Object.values(sliceAttachmentStore.byId).filter(
    (sliceAttachment) => sliceAttachment.buildUuid === props.build.uuid && sliceAttachment.state === SliceState.VALID
  );

  if (sliceAttachmentStore.fetched !== FetchingState.Fetching && validSliceAttachments.length === 0)
    return <DefectsUnavailable />;

  return (
    <Grid container spacing={4} style={{flex: 1, minHeight: '100%'}}>
      <Grid
        item
        xs={props.viewType === ViewType.GRID ? 6 : 12}
        style={{
          minHeight: defectCardHeight[props.viewType],
          height: defectCardHeight[props.viewType],
        }}
      >
        <DefectSummariesListGraph {...props} filterableDefectTypes={filterableDefectTypes} />
      </Grid>

      <Grid
        item
        xs={props.viewType === ViewType.GRID ? 6 : 12}
        style={{
          minHeight: defectCardHeight[props.viewType],
          height: defectCardHeight[props.viewType],
        }}
      >
        <DefectStatisticsViewport {...props} filterableDefectTypes={filterableDefectTypes} />
      </Grid>
    </Grid>
  );
}

function DefectsUnavailable() {
  return (
    <Grid container justifyContent="center">
      <Typography>
        <b>
          <i>Defect statistics cannot be generated without user-uploaded slice file data.</i>
        </b>
      </Typography>
    </Grid>
  );
}

const DefectStatisticsViewport = (props: LiveBuildDataProps & {filterableDefectTypes: string[]}) => {
  const {viewType} = props;
  const isXsScreen = useExtraSmallScreenSize();
  const isSmallScreen = useSmallScreenSize();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [viewportHeight, setViewportHeight] = useState<number>(0);
  const [overlayParams, setOverlayParams] = useState<DefectImageOverlays[]>([]);
  const [defectData, setDefectData] = useState<ViewportDefect>();
  const defectsTotal = useSelector((state: RootState) => state.defectStatisticStore.listTotal);

  const timer = useRef<null | ReturnType<typeof setTimeout>>(null);
  const [waitForStateChanges, setWaitForStateChanges] = useState<boolean>(false);

  useLayoutEffect(() => {
    const updateSize = () => {
      let height;
      if (viewType === ViewType.VERTICAL) {
        height = window.innerHeight - outerCardPadding.vertical - innerPagePadding;
      } else {
        const outerPage = outerCardPadding[viewType];
        if (window.innerHeight - outerPage <= 750) height = viewportMinSize;
        else height = (window.innerHeight - outerPage - innerPagePadding) * 0.5 - 16; // 16 gives it a bit of space between the defect list
      }

      setViewportHeight(height);
    };
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, [viewType]);

  const stopLoading = () => {
    setLoading(false);
    if (timer.current) {
      clearTimeout(timer.current);
    }
    timer.current = null;
  };

  const startLoading = () => {
    timer.current = setTimeout(() => {
      setLoading(true);
    }, 2000);
  };

  return (
    <Card style={{padding: isSmallScreen ? '1rem' : '1.5rem', height: '100%'}}>
      <Grid container style={{marginBottom: '15px'}}>
        <Grid item xs={8}>
          <Typography variant={isSmallScreen ? 'h6' : 'h4'} gutterBottom display="block">
            Individual Defects
          </Typography>
        </Grid>
      </Grid>

      <Grid container spacing={0} direction={gridDirection[viewType]} style={{height: 'calc(100% - 46px)'}}>
        {!!defectsTotal ? (
          <Grid
            item
            {...viewLayoutsXs[viewType].firstItem}
            style={{
              height: itemHeights[viewType],
              maxHeight: itemHeights[viewType],
              paddingLeft: viewType === ViewType.VERTICAL ? '32px' : '',
            }}
          >
            <DefectViewport
              isFitToWrapper={true}
              build={props.build}
              calibrationScale={props.calibrationScale}
              height={viewportHeight}
              isLoading={isLoading}
              overlays={overlayParams}
              defectData={defectData}
              loadedLayers={props.layerImages}
              stopLoading={stopLoading}
              waitForStateChanges={waitForStateChanges}
              setWaitForStateChanges={setWaitForStateChanges}
            />
          </Grid>
        ) : (
          <Grid
            style={{
              height: itemHeights[viewType],
              maxHeight: itemHeights[viewType],
            }}
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Typography>No Defects to display</Typography>
          </Grid>
        )}

        <DefectStatsTableGrid
          item
          {...viewLayoutsXs[viewType].secondItem}
          style={{
            padding: 0,
            zIndex: 5,
            height: itemHeights[viewType],
            maxHeight: itemHeights[viewType],
          }}
        >
          <DefectsTable
            currentDefectData={defectData}
            setDefectData={setDefectData}
            build={props.build}
            setOverlay={setOverlayParams}
            tableOptions={{
              maxBodyHeight: isXsScreen ? `calc(${tableHeight[viewType]} - 30px)` : tableHeight[viewType],
              minBodyHeight: isXsScreen ? `calc(${tableHeight[viewType]} - 30px)` : tableHeight[viewType],
            }}
            layerImages={props.layerImages}
            fetchLayerData={props.fetchLayerData}
            startLoading={startLoading}
            setWaitForStateChanges={setWaitForStateChanges}
            filterableDefectTypes={props.filterableDefectTypes}
          />
        </DefectStatsTableGrid>
      </Grid>
    </Card>
  );
};

const DefectStatsTableGrid = styled(Grid)`
  .generic-table-container {
    tr:last-of-type {
      td {
        border-bottom: 1px solid rgba(224, 224, 224, 1);
      }
    }
  }
`;

const EMPTY_SET = new Set<number>();

const DefectSummariesListGraph = ({
  viewType,
  build,
  partialLayerNumbers,
  filterableDefectTypes,
}: Pick<LiveBuildDataProps, 'build' | 'viewType' | 'partialLayerNumbers'> & {filterableDefectTypes: string[]}) => {
  const isSmallScreen = useSmallScreenSize();
  const isXsScreen = useExtraSmallScreenSize();
  const [excludePartialLayers, setExcludePartialLayers] = useState<boolean>(false);
  const [globalGraphConfig, setGlobalGraphConfig] = useState<GlobalGraphConfig>({
    rollingDataConfig: {
      rollingMethod: RollingMethod.average,
      rollingPeriod: 30,
    },
    useCoverage: false,
    showOriginal: true,
    showPartArea: false,
    showTrendline: false,
  });
  const [isGraphModalOpen, setGraphModalOpen] = useState(false);

  const {selectedDefects, setSelectedDefects, tableDefects} = useDefectSummaries(
    build.lastPrintedLayerId || build.numLayers,
    excludePartialLayers ? partialLayerNumbers : EMPTY_SET
  );

  const defectsSummaryTableSelectionProps = (rowData: DefectsSummaryRow) => {
    const selectedDefect = selectedDefects.find((defect) => rowData.uuid === defect.uuid);
    return {
      style: {
        color: selectedDefect?.tableData?.checked ? selectedDefect.graphConfig.dataLineSettings.lineColor : '',
      },
    };
  };

  const d3Color = useMemo(
    () => Plotly.d3.scale.category10().domain(Array.from({length: 10}, (_, i) => i.toString())),
    []
  );

  const generateSelectedDefectSummary = (defectSummary: DefectsSummaryRow): SelectedDefectSummary => {
    const lineColour = d3Color(`${defectSummary.tableData?.id ?? 1 - 1}`);

    return {
      ...defectSummary,
      graphConfig: {
        dataLineSettings: {
          lineColor: changeColourBrightness(lineColour, 50),
          lineType: LineType.solid,
          lineWeight: 1,
        },
        trendLineSettings: {
          lineColor: lineColour,
          lineType: LineType.dash,
          lineWeight: 2,
        },
        rollingDataConfig: globalGraphConfig.rollingDataConfig,
        showOriginal: true,
        showTrendline: false,
        useOverrides: false,
        showPartArea: defectSummary.partAreaGraph ? false : undefined,
      },
    };
  };

  const toggleSingleDefect = (defectSummary: DefectsSummaryRow) => {
    if (!selectedDefects.map((defect) => defect.uuid).includes(defectSummary.uuid)) {
      const newSelectedDefect = generateSelectedDefectSummary(defectSummary);
      setSelectedDefects([...selectedDefects, newSelectedDefect]);
    } else {
      const newSelectedDefects = selectedDefects.filter((defect) => defect.uuid !== defectSummary.uuid);
      setSelectedDefects(newSelectedDefects);
    }
  };

  const toggleAllDefects = (defectSummaries: DefectsSummaryRow[]) => {
    const selectedUUIDs = selectedDefects.map((defect) => defect.uuid);
    const tableUUIDs = defectSummaries.map((defect) => defect.uuid);
    const allSelected = defectSummaries.length === 0;
    const noneSelected = tableUUIDs.every((tUUID) => !selectedUUIDs.includes(tUUID));
    if (allSelected || !noneSelected) {
      setSelectedDefects([]);
    } else {
      const addedDefects = defectSummaries.map((defect) => {
        return generateSelectedDefectSummary(defect);
      });
      setSelectedDefects([...selectedDefects, ...addedDefects]);
    }
  };

  const toggleSelectedDefects = (defectSummaries: DefectsSummaryRow[], defectSummary?: DefectsSummaryRow) => {
    defectSummary ? toggleSingleDefect(defectSummary) : toggleAllDefects(defectSummaries);
  };

  return (
    <Card style={{padding: isSmallScreen ? '1rem' : '1.5rem', height: '100%'}}>
      <DefectsGraphOptionsModal
        open={isGraphModalOpen}
        onClose={() => setGraphModalOpen(false)}
        globalGraphConfig={globalGraphConfig}
        setGlobalGraphConfig={setGlobalGraphConfig}
        selectedDefects={selectedDefects}
        setSelectedDefects={setSelectedDefects}
      />
      <Grid
        container
        item
        xs={12}
        style={{marginBottom: '15px'}}
        justifyContent="space-between"
        alignItems="center"
        spacing={1}
        direction="row"
      >
        <Grid item xs={6}>
          <Typography variant={isSmallScreen ? 'h6' : 'h4'} gutterBottom display="block" noWrap>
            Defect Summaries
          </Typography>
        </Grid>
        {selectedDefects.length !== 0 ? (
          <Grid
            item
            style={{
              display: 'flex',
              flexWrap: 'nowrap',
              justifyContent: 'flex-end',
            }}
            xs={6}
          >
            <Button variant="outlined" onClick={() => setGraphModalOpen(true)} color="primary" size="small">
              Graph Options
            </Button>
          </Grid>
        ) : (
          <></>
        )}
      </Grid>

      <Grid container spacing={0} direction={gridDirection[viewType]} style={{height: 'calc(100% - 46px)'}}>
        <Grid
          item
          {...viewLayoutsXs[viewType].firstItem}
          style={{
            height: itemHeights[viewType],
            maxHeight: itemHeights[viewType],
          }}
        >
          <DefectsGraph globalGraphConfig={globalGraphConfig} selectedDefects={selectedDefects} />
        </Grid>
        <DefectsTableGrid
          item
          {...viewLayoutsXs[viewType].secondItem}
          style={{
            padding: 0,
            zIndex: 5,
            height: itemHeights[viewType],
            maxHeight: itemHeights[viewType],
          }}
        >
          <DefectSummaryTable
            build={build}
            tableOptions={{
              maxBodyHeight: isXsScreen ? `calc(${tableHeight[viewType]} - 30px)` : tableHeight[viewType],
              minBodyHeight: isXsScreen ? `calc(${tableHeight[viewType]} - 30px)` : tableHeight[viewType],
              selectionProps: defectsSummaryTableSelectionProps,
              headerStyle: {
                backgroundColor: 'none',
              },
            }}
            onSelectionChange={toggleSelectedDefects}
            defectSummaries={tableDefects}
            excludePartialLayers={excludePartialLayers}
            setExcludePartialLayers={setExcludePartialLayers}
            filterableDefectTypes={filterableDefectTypes}
          />
        </DefectsTableGrid>
      </Grid>
    </Card>
  );
};

const DefectsTableGrid = styled(Grid)`
  .generic-table-container {
    tr:last-of-type {
      td {
        border-bottom: 1px solid rgba(224, 224, 224, 1);
      }
    }
  }
`;

/*
 * Layout Grid sizes
 */
const viewLayoutsXs: ViewLayouts = {
  horizontal: {
    firstItem: {xs: 12},
    secondItem: {xs: 12},
  },
  vertical: {
    firstItem: {xs: 5, lg: 6, xl: 7},
    secondItem: {xs: 7, lg: 6, xl: 5},
  },
  grid: {
    firstItem: {xs: 12},
    secondItem: {xs: 12},
  },
};

/* List of Defects Table & Graph Height */

// CSS max/calc below uses viewheight for responsiveness. Hardcoded values are calculations on items in
// each container which takes up space

const innerPagePadding = 110;
const tablePadding = 104;
const viewportMinSize = 310;
// Size of elements outside of page container will be (100vh - size)
const outerCardPadding: any = {
  horizontal: 180,
  vertical: 180,
  grid: 170,
};

const gridDirection: any = {
  horizontal: 'column',
  vertical: 'row-reverse',
  grid: 'column',
};

const defectCardHeight: any = {
  horizontal: `max(calc(100vh - ${outerCardPadding.horizontal}px), 750px)`,
  vertical: `calc(100vh - ${outerCardPadding.vertical}px)`,
  grid: `max(calc(100vh - ${outerCardPadding.grid}px), 750px)`,
};

const itemHeights: any = {
  horizontal: '50%',
  vertical: '100%',
  grid: '50%',
};

const tableHeight: any = {
  horizontal: `calc(max(((100vh - ${outerCardPadding.horizontal}px - ${innerPagePadding}px) * 0.5 - ${tablePadding}px), 216px) - 20px)`,
  vertical: `calc(100vh - ${outerCardPadding.vertical}px - ${innerPagePadding}px - ${tablePadding}px - 20px)`,
  grid: `calc(max(((100vh - 20px - ${outerCardPadding.grid}px - ${innerPagePadding}px) * 0.5 - ${tablePadding}px), 216px) - 20px)`,
};
