import {assertUnreachable} from '@common/utils/utils';
import {Role} from '@common/api/models/users/IUser';

export type BuildSortOptions =
  | 'name'
  | 'createdAt'
  | 'start'
  | 'end'
  | 'machineName'
  | 'batchName'
  | 'state'
  | 'numLayers';

export enum BuildState {
  DRAFT = 'draft',
  STAGING = 'staging',
  PAUSED = 'paused',
  ACTIVE = 'active',
  SUCCEEDED = 'succeeded',
  COMPLETED = 'completed',
  FAILED = 'failed',
}

export enum EngagedBuildState {
  STAGING = 'staging',
  PAUSED = 'paused',
  ACTIVE = 'active',
}

export const CompletedBuildStates: BuildState[] = [BuildState.COMPLETED, BuildState.SUCCEEDED, BuildState.FAILED];

export interface IBuild {
  uuid: string;
  provisionerUuid?: string;
  organizationUuid: string;
  machineUuid?: string;
  machineParametersUuid?: string;
  deviceSerial?: string;
  batchUuid?: string;
  name: string;
  description?: string;
  createdAt: Date;
  start?: Date;
  end?: Date;
  state: BuildState;
  hasCalibrated: boolean;
  calibrationUuid?: string;
  sensorProfileUuid?: string;
  numLayers: number;
  lastPrintedLayerId?: number;
  lastHydraStatisticsLayerId?: number;
  sendEmail: boolean;
  sendMessage: boolean;
  analysisModel?: string;
  // parts: Part[];

  currentConfigUrl?: string; // S3 URI
  defaultConfigUrl?: string; // S3 URI
  updatedAt?: Date;
  deletedAt?: Date;
}

export interface IBuildGetRequest extends IBuild {
  hasIntensityReport?: boolean;
  includeDeleted?: boolean;
  hasSliceData?: boolean;
  hasReport?: boolean;
}

export interface IProvisionerGETResponse {
  uuid: string;
  role: Role;
  firstName: string;
  lastName: string;
}

export function isCompletedBuildState(state: BuildState) {
  switch (state) {
    case BuildState.DRAFT:
    case BuildState.STAGING:
    case BuildState.ACTIVE:
    case BuildState.PAUSED:
      return false;
    case BuildState.SUCCEEDED:
    case BuildState.COMPLETED:
    case BuildState.FAILED:
      return true;
  }
  assertUnreachable(state);
}

export function isEngagedBuildState(state: BuildState) {
  switch (state) {
    case BuildState.DRAFT:
      return false;
    case BuildState.STAGING:
    case BuildState.ACTIVE:
    case BuildState.PAUSED:
      return true;
    case BuildState.SUCCEEDED:
    case BuildState.COMPLETED:
    case BuildState.FAILED:
      return false;
  }
  assertUnreachable(state);
}

export function isRunningBuildState(state: BuildState) {
  switch (state) {
    case BuildState.DRAFT:
    case BuildState.STAGING:
      return false;
    case BuildState.ACTIVE:
    case BuildState.PAUSED:
      return true;
    case BuildState.SUCCEEDED:
    case BuildState.COMPLETED:
    case BuildState.FAILED:
      return false;
  }
  assertUnreachable(state);
}

export const MAX_LAYERS_ALLOWED_FOR_RESTART = 10;
