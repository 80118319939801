import React, {useEffect, useState} from 'react';
import {TextField, CircularProgress} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {analysisVersionsGET} from '../../../api/ajax/support';

type ModelOption = {value: string; label: string};

const AnalysisModelSelector = ({
  selectedModel,
  onSelected,
  isOrganizationPage = false,
}: {
  selectedModel: string | undefined;
  onSelected: (model: string | undefined) => void;
  isOrganizationPage?: boolean;
}) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [options, setOptions] = useState<Array<ModelOption>>([]);

  const selectedOption = options.find((option) => option.value === selectedModel) || null;

  async function fetchAnalysisModels() {
    const response = await analysisVersionsGET();
    if (response.success) {
      setOptions(
        response.data.versions.map((version) => ({
          value: version,
          // Only add the default label when not changing the default model.
          label: !isOrganizationPage && response.data.defaultVersion === version ? `${version} (Default)` : version,
        }))
      );
    }

    setLoading(false);
  }

  useEffect(() => {
    if (!options.length) {
      fetchAnalysisModels();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (option: string | null) => onSelected(option ?? undefined);
  const onOpen = () => setOpen(true);
  const onClose = () => setOpen(false);

  return (
    <Autocomplete
      fullWidth
      disableClearable
      filterSelectedOptions
      id={`analysis-model-select`}
      open={open}
      size="small"
      options={options}
      loading={loading}
      // @ts-ignore
      value={selectedOption ?? null}
      onChange={(_event: any, option: ModelOption | null) => onChange(option?.value || null)}
      getOptionLabel={(option: any) => option.label}
      onOpen={onOpen}
      onClose={onClose}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label={`Select ${isOrganizationPage ? 'Default ' : ''}Analysis Model`}
          placeholder={`Select ${isOrganizationPage ? 'Default ' : ''}Analysis Model`}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress size={20} style={{marginLeft: '10px'}} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};

export default AnalysisModelSelector;
