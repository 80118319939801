import React, {useEffect, useState} from 'react';
import {
  Button,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core';
import {Check, Clear, Edit} from '@material-ui/icons';
import styled from 'styled-components';

import moment from 'moment';
import PhoneInput from 'react-phone-input-2';

import {renderMobileNumber} from '../../utils/string';
import ConditionalTooltip from './Texts/ConditionalTooltip';

export interface BaseViewEditFieldProps {
  type: 'text' | 'date' | 'number' | 'datetime' | 'choice' | 'email' | 'mobile';
  readOnly?: boolean;
  units?: string;
  value?: any;
  valueText?: string;
  size?: 'small' | 'medium' | 'large';
  validateFn?: (value: any) => boolean;
  required?: boolean;
  multiline?: boolean;
  readOnlyTooltip?: string;
}

export interface ViewEditTextFieldProps extends BaseViewEditFieldProps {
  type: 'text' | 'email' | 'mobile';
  name: string;
  value?: string;
  saveValue: (val: string) => any;
}

export interface ViewEditDateFieldProps extends BaseViewEditFieldProps {
  type: 'date';
  name: string;
  value?: Date;
  saveValue: (val: Date) => any;
}

export interface ViewEditDateTimeFieldProps extends BaseViewEditFieldProps {
  type: 'datetime';
  name: string;
  value?: Date;
  saveValue: (val: Date) => any;
}

export interface ViewEditNumberFieldProps extends BaseViewEditFieldProps {
  type: 'number';
  name: string;
  value?: number;
  saveValue: (val: number) => any;
}

export interface ViewEditChoiceFieldProps<T extends number | string> extends BaseViewEditFieldProps {
  type: 'choice';
  name: string;
  value?: T;
  values: Array<{value: T; text: string}>;
  saveValue: (val: T) => any;
}

export type ViewEditFieldRowProps<T extends number | string> =
  | ViewEditTextFieldProps
  | ViewEditDateFieldProps
  | ViewEditNumberFieldProps
  | ViewEditDateTimeFieldProps
  | ViewEditChoiceFieldProps<T>;

export const InvisibleCell = styled(TableCell)`
  border-bottom-style: none;
`;

export default function ViewEditFieldRow<T extends number | string>(props: ViewEditFieldRowProps<T>) {
  const [saving, setSaving] = useState(false);
  const [editing, setEditing] = useState(false);
  const [editingValue, setEditingValue] = useState(props.value);

  useEffect(() => {
    setEditingValue(props.value);
  }, [props.value]);

  const handleOnChange = (e: any) => {
    let value = undefined;
    switch (props.type) {
      case 'text':
      case 'email':
        value = e.target.value;
        break;
      case 'datetime':
        value = moment(e.target.value as string, 'YYYY-MM-DDTHH:mm').toDate();
        break;
      case 'date':
        value = moment(e.target.value as string, 'YYYY-MM-DD').toDate();
        break;
      case 'number':
        value = Number(e.target.value);
        break;
      case 'choice':
        value = e.target.value;
        break;
      case 'mobile':
        value = e;
        break;
      default:
        value = e;
    }
    setEditingValue(value);
  };

  const handleSave = async () => {
    setSaving(true);
    // Field is required and no input
    if (!!props.required && editingValue === '') {
      setSaving(false);
      return;
    }

    // Validate input value
    if (props.validateFn && !props.validateFn(editingValue)) {
      // Error validating value
      setSaving(false);
      return;
    }

    try {
      await (props.saveValue as any)(editingValue);
    } finally {
      setSaving(false);
      setEditing(false);
    }
  };

  const handleCancel = () => {
    setEditing(false);
  };

  const dateFormatString = 'YYYY-MM-DD';
  const valueString =
    props.type === 'date'
      ? moment(props.value).format(dateFormatString).toString()
      : props.type === 'mobile'
      ? renderMobileNumber(props.value)
      : props.value;

  const commonTextFieldProps = {
    InputProps: {
      onChange: handleOnChange,
      endAdornment: <InputAdornment position="end">{props.units}</InputAdornment>,
    },
    autoFocus: true,
  };

  return (
    <TableRow>
      <InvisibleCell>
        <Typography>
          <strong>{props.name}</strong>
        </Typography>
      </InvisibleCell>
      {editing ? (
        <React.Fragment>
          <InvisibleCell>
            <Grid container>
              <Grid item xs={12}>
                {props.type === 'text' ? (
                  <TextField
                    value={editingValue}
                    {...commonTextFieldProps}
                    style={{
                      width: '100%',
                    }}
                    onKeyDown={(e: any) => {
                      if (e.key === 'Enter') handleSave();
                    }}
                    multiline={props.multiline}
                  />
                ) : props.type === 'number' ? (
                  <TextField
                    value={editingValue}
                    type="number"
                    onKeyDown={(e: any) => {
                      if (e.key === 'Enter') handleSave();
                    }}
                    {...commonTextFieldProps}
                  />
                ) : props.type === 'datetime' ? (
                  <TextField
                    value={moment(editingValue).format('YYYY-MM-DDTHH:mm')}
                    type="datetime-local"
                    {...commonTextFieldProps}
                  />
                ) : props.type === 'date' ? (
                  <TextField
                    value={moment(editingValue).format(dateFormatString)}
                    type="date"
                    {...commonTextFieldProps}
                  />
                ) : props.type === 'choice' ? (
                  <TextField select value={editingValue} label={props.units} fullWidth {...commonTextFieldProps}>
                    {props.values.map((v, i) => (
                      <MenuItem key={i} value={v.value}>
                        {v.text}
                      </MenuItem>
                    ))}
                  </TextField>
                ) : props.type === 'email' ? (
                  <TextField
                    value={editingValue}
                    onKeyDown={(e: any) => {
                      if (e.key === 'Enter') handleSave();
                    }}
                    {...commonTextFieldProps}
                  />
                ) : props.type === 'mobile' ? (
                  <PhoneInput
                    country="au"
                    value={props.value}
                    onChange={handleOnChange}
                    onKeyDown={(e: any) => {
                      if (e.key === 'Enter') handleSave();
                    }}
                    masks={{au: '. .... ....'}}
                  />
                ) : null}
              </Grid>
            </Grid>
          </InvisibleCell>
          <InvisibleCell style={{width: '88px', paddingLeft: 0, paddingRight: 0}}>
            <Grid
              item
              style={{
                display: 'inline-flex',
              }}
            >
              <IconButton
                color="primary"
                onClick={handleSave}
                style={{paddingTop: '6px', paddingBottom: '6px'}}
                disabled={saving}
              >
                {saving ? <CircularProgress size={15} /> : <Check fontSize="small" />}
              </IconButton>
              <IconButton onClick={handleCancel} style={{paddingTop: '6px', paddingBottom: '6px'}} disabled={saving}>
                <Clear fontSize="small" />
              </IconButton>
            </Grid>
          </InvisibleCell>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <InvisibleCell align="right">
            <Typography style={{wordBreak: 'break-word'}}>
              <ConditionalTooltip hideTooltip={!props.readOnlyTooltip} tooltip={props.readOnlyTooltip || ''}>
                <>
                  {props.valueText || valueString}
                  {props.units && props.value && ' ' + props.units}
                </>
              </ConditionalTooltip>
            </Typography>
          </InvisibleCell>
          <InvisibleCell style={{width: '88px', paddingLeft: 0, paddingRight: 0}} align="center">
            {!props.readOnly && (
              <Button onClick={() => setEditing(true)}>
                <Edit fontSize="small" />
              </Button>
            )}
          </InvisibleCell>
        </React.Fragment>
      )}
    </TableRow>
  );
}
