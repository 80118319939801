import React, {useEffect} from 'react';
import {Box, Divider as MuiDivider, Grid, Switch, Typography} from '@material-ui/core';
import {spacing} from '@material-ui/system';
import {useSelector} from 'react-redux';
import styled from 'styled-components';

import {MachineAvatar} from '../../../components/molecules/avatars/MachineAvatar';
import BuildDataArchiveDownload from '../../../components/molecules/Forms/BuildDataArchiveDownload/BuildDataArchiveDownload';
import ManualBuildActionButtons from '../../../components/molecules/ManualControls/ManualBuildActionButtons';
import ManualBuildStateButtons from '../../../components/molecules/ManualControls/ManualBuildStateButtons';
import {ManualDeviceStateButtons} from '../../../components/molecules/ManualControls/ManualDeviceStateButtons';
import {useMachineStoreActions} from '../../../store/actions';
import {WebcamButton} from '../liveBuild/shared/WebcamButton';
import {RootState} from '../../../store/reducers';
import {LiveBuildProps} from '../liveBuild';
import {buildsByUuidPATCH} from '../../../api/ajax/builds';
import {usePermissionsForBuild} from '../../../utils/utilHooks';
import ConditionalTooltip from '../../../components/atoms/Texts/ConditionalTooltip';
import ProvisionerDetails from './ProvisionerDetails';
import DeleteBuildButton from '../liveBuild/activeBuildPages/DeleteBuildButton';
import {Role} from '@common/api/models/users/IUser';

const Divider = styled(MuiDivider)(spacing);

const CustomSwitch = styled(Switch)`
  &.MuiSwitch-root {
  }
`;

function BuildManualActions({build}: LiveBuildProps) {
  const deviceStore = useSelector((state: RootState) => state.deviceStore);
  const machineActions = useMachineStoreActions();
  const machineStore = useSelector((state: RootState) => state.machineStore);
  const featureFlags = useSelector((state: RootState) => state.support.featureFlags);
  const currentUser = useSelector((state: RootState) => state.auth.user);
  const {machinePermission} = usePermissionsForBuild(build);

  useEffect(() => {
    machineActions.ensureConsistent({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const device = deviceStore.byId[build.deviceSerial!];
  const machine = machineStore.byId[build.machineUuid!];

  return (
    <Grid container spacing={2}>
      {device && machine && (
        <Grid item xs={12}>
          <MachineAvatar machine={machine} device={device} />
        </Grid>
      )}

      <Divider my={6} style={{width: '100%'}} />

      {featureFlags.bulkDataDownloader && currentUser && currentUser.role > Role.TECHNICIAN && (
        <>
          <BuildDataArchiveDownload build={build} />

          <Divider my={6} style={{width: '100%'}} />
        </>
      )}

      <ProvisionerDetails build={build} />

      <Grid item xs={12} style={{paddingTop: '20px'}}>
        <Typography variant={'h6'}>Build Actions (advanced)</Typography>
      </Grid>
      <Grid item xs={12}>
        <ConditionalTooltip
          tooltip="You don't have permission to access this build's machine."
          hideTooltip={!!machinePermission}
        >
          <ManualBuildActionButtons build={build} disabled={!machinePermission} />
        </ConditionalTooltip>
      </Grid>

      <Grid item xs={12} style={{paddingTop: '20px'}}>
        <Typography variant={'h6'}>Set Build State (advanced)</Typography>
        <Typography style={{paddingTop: '10px'}}>Build State: {build.state}</Typography>
      </Grid>
      <Grid item xs={12}>
        <ConditionalTooltip
          tooltip="You don't have permission to access this build's machine."
          hideTooltip={!!machinePermission}
        >
          <ManualBuildStateButtons build={build} disabled={!machinePermission} />
        </ConditionalTooltip>
      </Grid>

      <Grid item xs={12} style={{marginTop: '20px'}}>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant={'h6'}>Build Notification Preferences</Typography>
          </Grid>
          <Box display="flex" flexDirection="column">
            <Box display="flex">
              <Typography style={{width: '100px', marginTop: '10px'}}>Email</Typography>
              <CustomSwitch
                checked={build.sendEmail}
                inputProps={{'aria-label': 'Switch email'}}
                onChange={(event) => {
                  buildsByUuidPATCH(build.uuid, {
                    sendEmail: event.target.checked,
                  });
                }}
              />
            </Box>
            {/* SMS Disabled for the time being, it needs a rework. When it comes back, it may be enabled in on-prem */}
            {/* {!envConfig.isOnPremise && (
              <Box display="flex">
                <Typography style={{width: '100px', marginTop: '10px'}}>Text Message</Typography>

                <CustomSwitch
                  checked={build.sendMessage}
                  inputProps={{'aria-label': 'Switch message'}}
                  onChange={(event) => {
                    buildsByUuidPATCH(build.uuid, {
                      sendMessage: event.target.checked,
                    });
                  }}
                />
              </Box>
            )} */}
          </Box>
        </Grid>
      </Grid>

      {device && (
        <>
          <Grid item xs={12} style={{paddingTop: '15px'}}>
            <Typography variant={'h6'}>Device Control</Typography>
            <Typography style={{paddingTop: '10px'}}>Advanced device controls</Typography>
          </Grid>
          <Grid item xs={12}>
            <ManualDeviceStateButtons deviceSerial={device.serial} />
          </Grid>
        </>
      )}

      {device && (
        <Grid item xs={12} style={{paddingTop: '15px'}}>
          <WebcamButton webcamHosts={device.webcamHosts} />
        </Grid>
      )}

      {currentUser && (currentUser.role > Role.TECHNICIAN || currentUser.uuid === build.provisionerUuid) && (
        <Grid item xs={12} style={{paddingTop: '15px'}}>
          <Typography variant={'h6'} style={{marginBottom: '8px'}}>
            Delete Build
          </Typography>
          <DeleteBuildButton build={build} />
        </Grid>
      )}
    </Grid>
  );
}

export default BuildManualActions;
