import Konva from 'konva';
import {Filter} from 'konva/types/Node';

import {AnalysisType2D} from '@common/api/models/builds/data/defects/IDefect';

export type AnalysisOverlayParams = {
  [analysisType in AnalysisType2D]: ImageOverlaysParams;
};

declare type globalCompositeOperationType =
  | ''
  | 'source-over'
  | 'source-in'
  | 'source-out'
  | 'source-atop'
  | 'destination-over'
  | 'destination-in'
  | 'destination-out'
  | 'destination-atop'
  | 'lighter'
  | 'copy'
  | 'xor'
  | 'multiply'
  | 'screen'
  | 'overlay'
  | 'darken'
  | 'lighten'
  | 'color-dodge'
  | 'color-burn'
  | 'hard-light'
  | 'soft-light'
  | 'difference'
  | 'exclusion'
  | 'hue'
  | 'saturation'
  | 'color'
  | 'luminosity';

export interface ImageOverlaysParams {
  filters: Array<Filter>;
  filterParams?: {
    red?: number;
    green?: number;
    blue?: number;
    alpha?: number;
    globalCompositeOperation?: globalCompositeOperationType;
    opacity?: number;
    transparentBackground?: {
      r: number;
      g: number;
      b: number;
    };
  };
}

export const defaultOverlayParams: AnalysisOverlayParams = {
  [AnalysisType2D.Layer]: {
    filters: [],
  },
  [AnalysisType2D.ColourMap]: {
    filters: [],
    filterParams: {
      // opacity: 0.7,
    },
  },
  [AnalysisType2D.LayerMask]: {
    filters: [],
    filterParams: {},
  },
  [AnalysisType2D.Lsdd]: {
    filters: [Konva.Filters.RGBA],
    filterParams: {
      red: 0,
      green: 0,
      blue: 255,
      alpha: 0.5,
      globalCompositeOperation: 'lighter',
    },
  },
  [AnalysisType2D.EdgeDistortion]: {
    filters: [],
    filterParams: {
      globalCompositeOperation: 'lighter',
    },
  },
  [AnalysisType2D.RecoaterStreaks]: {
    filters: [],
    filterParams: {
      globalCompositeOperation: 'lighter',
    },
  },
  [AnalysisType2D.Spatter]: {
    filters: [],
    filterParams: {
      globalCompositeOperation: 'lighter',
    },
  },
  [AnalysisType2D.HotCold]: {
    filters: [],
    filterParams: {
      globalCompositeOperation: 'lighter',
    },
  },
  [AnalysisType2D.ImageBlur]: {
    filters: [],
    filterParams: {
      globalCompositeOperation: 'lighter',
    },
  },
  [AnalysisType2D.HatchVectors]: {
    filters: [],
    filterParams: {
      globalCompositeOperation: 'lighter',
    },
  },
  [AnalysisType2D.HatchAngles]: {
    filters: [],
    filterParams: {
      globalCompositeOperation: 'lighter',
    },
  },
  [AnalysisType2D.HatchFrequency]: {
    filters: [],
    filterParams: {
      globalCompositeOperation: 'lighter',
    },
  },
  [AnalysisType2D.LatticeStruts]: {
    filters: [],
    filterParams: {
      globalCompositeOperation: 'lighter',
    },
  },
  [AnalysisType2D.PlanarDistortion]: {
    filters: [],
    filterParams: {
      globalCompositeOperation: 'lighter',
    },
  },
  [AnalysisType2D.SupportMaterial]: {
    filters: [],
    filterParams: {
      globalCompositeOperation: 'lighter',
    },
  },
  [AnalysisType2D.Porosity]: {
    filters: [],
    filterParams: {
      globalCompositeOperation: 'lighter',
    },
  },
};
