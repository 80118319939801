import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {useSelector} from 'react-redux';
import styled from 'styled-components';
import {Settings} from '@material-ui/icons';
import {
  Grid,
  Card,
  CardContent,
  TextField,
  CardHeader,
  Typography,
  Box,
  IconButton,
  Tooltip,
  Divider as MuiDivider,
} from '@material-ui/core';
import {Alert as MuiAlert} from '@material-ui/lab';
import {spacing} from '@material-ui/system';

import {IMachinesTableGETResponse} from '@common/api/models/devices/machines/IMachine';
import {OrderDirection} from '@common/utils/ordering/ordering';
import {useSmallScreenSize} from '../../../utils/utilHooks';
import {DraftBuildProps} from './DraftBuild';
import {useDraftBuild} from './DraftBuildContext';
import {useDeviceStoreActions, useMachineStoreActions} from '../../../store/actions/index';
import {RootState} from '../../../store/reducers/index';
import {FetchingState} from '../../../store/model/liveUpdateStore';
import LoadingPage from '../../../components/organisms/LoadingPage';
import SearchAndSelect from '../../../components/molecules/Selector/SearchAndSelect';
import {machinesAllGET} from '../../../api/ajax/machines';
import DeviceAvailabilityStatus from '../../../components/atoms/Status/DeviceAvailabilityStatus';
import {SaveAndClose, SaveAndStart, useMachineStatusText} from './StepNavigationButtons';
import {SelectSensorProfile} from './draftBuildSteps/BuildConfigurationStep';
import {SliceFilesCard} from '../shared/SliceFilesCard';
import AnalysisModelSelector from '../../../components/molecules/Selector/AnalysisModelSelector';
import {Role} from '@common/api/models/users/IUser';

const Alert = styled(MuiAlert)(spacing);

const Divider = styled(MuiDivider)(spacing);

function SimpleDraftBuild({build}: DraftBuildProps) {
  const isSmallScreen = useSmallScreenSize();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const {setDraftWithSave, draftBuild} = useDraftBuild();
  const machineStoreActions = useMachineStoreActions();
  const deviceActions = useDeviceStoreActions();
  const machines = useSelector((state: RootState) => state.machineStore.byId);
  const devices = useSelector((state: RootState) => state.deviceStore.byMachineUuid);
  const devicesFetched = useSelector((state: RootState) => state.deviceStore.fetched);
  const machinesFetched = useSelector((state: RootState) => state.machineStore.fetched);
  const currentUser = useSelector((state: RootState) => state.auth.user!);
  const machineStatusText = useMachineStatusText(draftBuild);

  useEffect(() => {
    machineStoreActions.ensureConsistent({});
    deviceActions.ensureConsistent({});

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [draftBuild.machineUuid]);

  useEffect(() => {
    const fetchedStates = [FetchingState.Error, FetchingState.Fetched];
    if (fetchedStates.includes(devicesFetched) && fetchedStates.includes(machinesFetched)) {
      setLoading(false);
    }
  }, [devicesFetched, machinesFetched]);

  // Automatically set the machine if there is only one machine.
  useEffect(() => {
    if (!draftBuild.machineUuid && Object.values(machines).length === 1) {
      const machine = Object.values(machines)[0];
      const device = devices[machine.uuid];
      if (!device) return;

      // @ts-ignore
      setDraftWithSave((build) => {
        return {
          ...build,
          machineUuid: machine.uuid,
          deviceSerial: device.serial,
          sensorProfileUuid: device.sensorProfileUuid || null,
        };
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [machines, devices]);

  function enterAdvancedMode() {
    localStorage.setItem(`${build.uuid}-advanced-draft`, 'true');
    history.push(`/builds/uuid/${build.uuid}/draft/advanced/`);
  }

  if (loading) return <LoadingPage loadingText="Loading Build..." />;

  return (
    <Grid container spacing={isSmallScreen ? 3 : 6} justifyContent="center">
      <Grid item xs={12}>
        <Alert severity="info" mt={2}>
          Material batch, machine parameters, user access and notification settings will be copied across from the most
          recently completed build for the selected machine. If you wish to change these, please enter advanced mode by
          clicking on the settings icon below.
        </Alert>
      </Grid>
      <Grid item xs={12} sm={10} md={6} lg={4} style={{maxWidth: '500px'}}>
        <Card>
          <CardHeader
            style={{paddingBottom: '0px'}}
            title={
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography variant="h5">Draft Build Details</Typography>
                <Tooltip title={<Typography>Enter advanced mode</Typography>}>
                  <IconButton onClick={enterAdvancedMode}>
                    <Settings />
                  </IconButton>
                </Tooltip>
              </Box>
            }
          />
          <CardContent>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <BuildName />
              </Grid>
              {machineStatusText ? (
                <Grid item xs={12}>
                  <Alert severity="info">{machineStatusText}</Alert>
                </Grid>
              ) : (
                <></>
              )}
              <Grid item xs={12} style={{marginTop: '8px'}}>
                <MachineSelector />
              </Grid>
              <Grid item xs={12} style={{marginTop: '8px'}}>
                <SensorProfileSelector />
              </Grid>
              {currentUser.role >= Role.ADMINISTRATOR && (
                <Grid item xs={12} style={{marginTop: '8px'}}>
                  <AnalysisModelSelector
                    selectedModel={build.analysisModel}
                    onSelected={(model) => {
                      setDraftWithSave((draft) => ({...draft, analysisModel: model}));
                    }}
                  />
                </Grid>
              )}

              <SliceFilesCard buildUuid={build.uuid} orgUuid={build.organizationUuid} isDraft />

              <Divider />

              <Grid item container xs={12}>
                <Box display="flex" justifyContent="space-between" width="100%">
                  <Box width="45%">
                    <SaveAndClose isSimpleWorkflow />
                  </Box>
                  <Box width="45%">
                    <SaveAndStart isSimpleWorkflow />
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

export default SimpleDraftBuild;

const StyledBox = styled(Box)`
  ul {
    margin-top: 0px;
  }
`;

function BuildName() {
  const {setDraftWithSave, draftBuild} = useDraftBuild();

  function handleChange(name: string) {
    setDraftWithSave((build) => ({...build, name: name || draftBuild.name}));
  }

  return (
    <TextField
      label="Build Name*"
      variant="outlined"
      fullWidth
      size="small"
      value={draftBuild.name}
      onChange={(e) => handleChange(e.target?.value)}
    />
  );
}

function MachineSelector() {
  const {setDraftWithSave, draftBuild} = useDraftBuild();
  const machines = useSelector((state: RootState) => state.machineStore.byId);
  const devices = useSelector((state: RootState) => state.deviceStore.byMachineUuid);

  const selectedMachine = draftBuild.machineUuid ? machines[draftBuild.machineUuid] : undefined;

  function selectMachine(machine: IMachinesTableGETResponse | undefined) {
    const device = machine ? devices[machine.uuid] : null;

    // @ts-ignore
    setDraftWithSave((build) => {
      return {
        ...build,
        machineUuid: machine?.uuid || null,
        deviceSerial: device?.serial || null,
        sensorProfileUuid: device?.sensorProfileUuid || null,
      };
    });
  }

  async function fetchMachines(search: string) {
    const filter = {take: 50, sortBy: {availability: OrderDirection.ASC}};
    const res = await machinesAllGET(search ? {name: {like: search}, ...filter} : filter);
    if (res.success) {
      return res.data;
    }
    return [];
  }

  return (
    <SearchAndSelect
      overrideLabel
      label="Machine*"
      selected={selectedMachine}
      setSelected={selectMachine}
      fetchFunction={fetchMachines}
      getSuggestionValue={(machine: IMachinesTableGETResponse) => machine.name}
      renderOptionSecondary={(machine: IMachinesTableGETResponse) => (
        <StyledBox marginLeft="12px">
          <DeviceAvailabilityStatus availability={machine.availability!} />
        </StyledBox>
      )}
      isSelected={(machine) => machine.uuid === draftBuild.machineUuid}
    />
  );
}

function SensorProfileSelector() {
  const {setDraftWithSave, draftBuild} = useDraftBuild();
  const devices = useSelector((state: RootState) => state.deviceStore.byMachineUuid);

  return (
    <SelectSensorProfile
      selectedProfileUuid={draftBuild.sensorProfileUuid}
      deviceSerial={draftBuild.deviceSerial}
      handleProfileSelected={(profileUuid) => setDraftWithSave((build) => ({...build, sensorProfileUuid: profileUuid}))}
      defaultProfileUuid={draftBuild.machineUuid ? devices[draftBuild.machineUuid]?.sensorProfileUuid : undefined}
    />
  );
}
