import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {Box, CardContent, Card, Typography, Grid} from '@material-ui/core';
import {Alert} from '@material-ui/lab';
import {Role} from '@common/api/models/users/IUser';
import Header from '../../components/organisms/Header';
import {organizationByUuidPATCH} from '../../api/ajax/organizations';
import AnalysisModelSelector from '../../components/molecules/Selector/AnalysisModelSelector';
import {RootState} from '../../store/reducers';
import {useOrganizationStoreActions} from '../../store/actions';

export default function OrganizationSettingsPage() {
  const currentUser = useSelector((state: RootState) => state.auth.user);
  const organizationStoreActions = useOrganizationStoreActions();
  const organization = useSelector((state: RootState) =>
    currentUser ? state.organizationStore.byId[currentUser.organizationUuid] : undefined
  );

  useEffect(() => {
    if (currentUser && currentUser.role >= Role.ADMINISTRATOR) {
      organizationStoreActions.ensureConsistent({uuid: currentUser!.organizationUuid});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  return (
    <React.Fragment>
      <Header
        helmet={`Organization Settings`}
        title={`Organization Settings`}
        breadcrumbs={[{title: 'Organization Settings', path: '/organizations/settings'}]}
      />

      <Box display="flex" justifyContent="center" width="100%">
        <Box display="flex" maxWidth="600px" flexWrap="wrap">
          {currentUser && currentUser.role >= Role.ADMINISTRATOR && organization && (
            <Card style={{width: '100%', marginBottom: '1rem'}}>
              <CardContent style={{paddingBottom: '16px'}}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} style={{display: 'flex', alignItems: 'center'}}>
                    <Typography>
                      <strong>Analysis model</strong>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} style={{display: 'flex', alignItems: 'center'}}>
                    <AnalysisModelSelector
                      selectedModel={organization.defaultAnalysisModel}
                      onSelected={(model) => {
                        organizationByUuidPATCH(organization.uuid, {defaultAnalysisModel: model});
                      }}
                      isOrganizationPage
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          )}

          <Alert severity="info" style={{width: '100%'}}>
            The following security settings can be optionally enabled for your organization.
            <ul>
              <li>Two-factor authentication</li>
              <li>Password Rotation</li>
            </ul>
            If you wish to enable these, please get in touch via{' '}
            <a href="mailto:support@additiveassurance.com">support@additiveassurance.com</a>.
          </Alert>
        </Box>
      </Box>
    </React.Fragment>
  );
}
