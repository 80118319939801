import React, {useEffect, useState} from 'react';
import {Scene} from 'three';
import {cloneDeep} from 'lodash';
import {useSelector} from 'react-redux';
import {Box, Button} from '@material-ui/core';
import {AnalysisType3D} from '@common/api/models/builds/data/defects/IDefect';
import {ICTReportGETResponse} from '@common/api/models/builds/data/ICTReport';
import {View3DViewportParams} from '../../components/molecules/Viewport/3D/View3DViewport';
import Base3DViewport, {initialParams} from '../../components/molecules/Viewport/3D/Base3DViewport';
import {ViewportMessage, showOverlayMessage} from '../../components/molecules/Viewport/3D/ViewportMessage';
import {CTAlignmentViewportSidebar} from '../../components/molecules/Viewport/3D/Sidebar/View3DViewportSidebar';
import {useCtReportStoreActions, usePartStoreActions} from '../../store/actions';
import {RootState} from '../../store/reducers';
import {useCTPointClouds} from '../../components/molecules/Viewport/3D/pointCloudHooks/useCTPointClouds';
import {usePointCloudEffects} from '../../components/molecules/Viewport/3D/pointCloudHooks/usePointCloudEffects';
import {SOURCE_PART_COLOR, TARGET_PART_COLOR} from '../../components/molecules/Viewport/3D/AnalysisTypeHelper';
import {AnalysisTypeMap} from '../../components/molecules/Viewport/3D/types/pointCloudTypes';
import {toggleAnalysisTypeVisibility} from '../../components/molecules/Viewport/3D/viewportFunctions/control';
import {initialPointCloudParams} from '../../components/molecules/Viewport/3D/types/params';
import {useExtraSmallScreenSize} from '../../utils/utilHooks';
import {TransformHelper} from '../../components/molecules/Viewport/3D/helpers/transformHelper';

const initialCTAlignmentParams: View3DViewportParams = {
  ...initialParams,
  ...initialPointCloudParams,
  clippingPlaneEnabled: false,
  availableParts: [],
  selectedParts: [],
  availableAnalysisTypes: {ctModel: true, model: true} as AnalysisTypeMap<boolean>,
  selectedAnalysisTypes: {ctModel: true, model: true} as AnalysisTypeMap<boolean>,
  ctAlignmentHelperEnabled: true,
  centerAllParts: true,
  showGrid: false,
  throughPlane: true,
  pointSize: 0.1,
  pointLimit: 6e5,
  overrideAnalysisTypeColors: true,
  analysisTypeColorOverrides: {ctModel: TARGET_PART_COLOR},
  overridePointColour: true,
  overrideColour: SOURCE_PART_COLOR,
};

function CTAlignmentViewport({
  currentReport,
  stageHeight,
}: {
  currentReport: ICTReportGETResponse;
  stageHeight?: number;
}) {
  const partStoreActions = usePartStoreActions();
  const ctStoreActions = useCtReportStoreActions();
  const [isXAxisInverted, setIsXAxisInverted] = useState(false);
  const [params, setParams] = useState<View3DViewportParams>({
    ...initialCTAlignmentParams,
  });
  const isSmallScreen = useExtraSmallScreenSize();

  const [scene] = useState(() => new Scene());
  const rerenderRef = React.useRef(() => {});
  // Function to force a rerender of the 3D viewport
  const renderScene = rerenderRef.current;

  const {viewportState, pointClouds, sceneBounds} = useCTPointClouds(currentReport, params, renderScene);

  useEffect(() => {
    const filter = {
      uuid: currentReport.partUuid,
    };
    partStoreActions.ensureConsistent({...filter, includeFirstLayer: true}, filter);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentReport.partUuid]);

  const sourcePart = useSelector((state: RootState) => state.partStore.byId[currentReport.partUuid]);

  useEffect(() => {
    if (sourcePart) {
      setParams((params) => ({
        ...params,
        selectedParts: cloneDeep([sourcePart]),
        availableParts: cloneDeep([sourcePart]),
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sourcePart]);

  useEffect(() => {
    toggleAnalysisTypeVisibility(params, setParams, pointClouds, renderScene);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.selectedAnalysisTypes, pointClouds.numParts, renderScene]);

  function invertCTModelOnXAxis() {
    setIsXAxisInverted(!isXAxisInverted);
    pointClouds.forEachPointCloud((partModel) => {
      if (partModel.userData.analysisType === AnalysisType3D.CTModel) {
        partModel.scale.x *= -1;
        // Update the transformation matrix
        partModel.updateMatrix();
        ctStoreActions.updateViewportTransform(partModel.matrix.transpose());
      }
    });
    renderScene();
  }

  // For Rotation Helper to attach to the CT PointCloud.
  const ctPointCloud = pointClouds.group.getObjectByName('ctModel');
  const ctAlignmentHelperEnabled = (params as View3DViewportParams).ctAlignmentHelperEnabled;

  useEffect(() => {
    if (!ctPointCloud) return;
    const rotationHelper = scene.getObjectByName('rotationHelper') as TransformHelper;
    if (ctAlignmentHelperEnabled) {
      if (rotationHelper && !rotationHelper.linked) rotationHelper.link(ctPointCloud);
    } else {
      rotationHelper.unlink(ctPointCloud);
    }
    renderScene();
  }, [ctPointCloud, ctAlignmentHelperEnabled, scene, renderScene]);

  usePointCloudEffects(pointClouds, params, scene, renderScene, viewportState);

  return (
    <Base3DViewport
      scene={scene}
      sceneGroup={pointClouds}
      sceneBounds={sceneBounds}
      params={params}
      sidebar={
        <CTAlignmentViewportSidebar
          params={params}
          sceneBounds={sceneBounds}
          setParams={setParams}
          resetParams={() => {}}
          viewportState={viewportState}
        />
      }
      height={stageHeight}
      sidebarInitOpen={!isSmallScreen}
      overlayMessage={
        <ViewportMessage
          viewportState={viewportState}
          noSelectionTitle="No part selected"
          noSelectionMessage="Select a part in the tree to preview."
        />
      }
      showOverlayMessage={showOverlayMessage(viewportState)}
      showHelpers={['viewing', 'loading'].includes(viewportState)}
      rerenderRef={rerenderRef}
      updateTransforms={ctStoreActions.updateViewportTransform}
      leftButtons={
        <InvertCTModelOnXAxis invertCTModelOnXAxis={invertCTModelOnXAxis} isXAxisInverted={isXAxisInverted} />
      }
    />
  );
}

export default CTAlignmentViewport;

function InvertCTModelOnXAxis({
  invertCTModelOnXAxis,
  isXAxisInverted,
}: {
  invertCTModelOnXAxis: () => void;
  isXAxisInverted: boolean;
}) {
  return (
    <Box position="absolute" bottom="24px" left="24px">
      <Button variant="contained" onClick={invertCTModelOnXAxis}>
        {isXAxisInverted ? 'Uninvert' : 'Invert'} CT Model on X Axis
      </Button>
    </Box>
  );
}
