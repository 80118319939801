import React, {useCallback, useEffect, useMemo, useReducer, useState} from 'react';
import {MaterialTableProps} from 'material-table';
import {useSelector} from 'react-redux';
import {Button, CircularProgress, Typography} from '@material-ui/core';
import {GetApp} from '@material-ui/icons';
import {BuildState, IBuild} from '@common/api/models/builds/IBuild';
import {DefectsSummaryRow} from '../../../pages/builds/liveBuild/activeBuildPages/DefectsPage';
import {useExtraSmallScreenSize} from '../../../utils/utilHooks';
import {downloadResource} from '../../../utils/webtools';
import {RootState} from '../../../store/reducers/index';
import GenericTable from './GenericTable';
import * as qs from 'qs';
import {DefectType, getPrettyDefectType} from '@common/api/models/builds/data/defects/IDefect';

let refreshInterval: null | ReturnType<typeof setTimeout> = null;

export const DefectSummaryTable = ({
  build,
  onSelectionChange,
  tableOptions = {},
  defectSummaries,
  excludePartialLayers,
  setExcludePartialLayers,
  filterableDefectTypes,
}: {
  build: IBuild;
  tableOptions?: MaterialTableProps<DefectsSummaryRow>['options'];
  onSelectionChange?: (data: DefectsSummaryRow[], rowData: DefectsSummaryRow) => void;
  defectSummaries: DefectsSummaryRow[];
  excludePartialLayers: boolean;
  setExcludePartialLayers: (excluded: boolean) => void;
  filterableDefectTypes?: string[];
}) => {
  const [forcedRefresh, forceRefresh] = useReducer((x) => x + 1, 0);
  const isXsScreen = useExtraSmallScreenSize();
  const [csvRequesting, setCsvRequesting] = useState(false);
  const defectSummaryFilters = useSelector((state: RootState) => state.defectSummaryStore.listFilters);

  const defectSummaryBaseFilters = useMemo(() => ({buildUuid: build.uuid}), [build.uuid]);

  const useDefectSummaries = useCallback(() => {
    return defectSummaries;
  }, [defectSummaries]);

  const handleCsvDownload = async () => {
    setCsvRequesting(true);
    const query = qs.stringify({...defectSummaryFilters, ...defectSummaryBaseFilters}, {arrayFormat: 'brackets'});
    await downloadResource(
      `/api/defectSummary/export` + (query && '?' + query),
      `${build.name}-defect-summary-export.csv`
    );
    setCsvRequesting(false);
  };

  useEffect(() => {
    // Defect summaries doesn't have WebSockets, and I don't plan on adding them for the moment.
    // As a workaround, refresh the table every 60 seconds if the build is active, to fetch the latest data.
    if (build.state === BuildState.ACTIVE) {
      refreshInterval = setInterval(() => forceRefresh(), 60_000);
    }
    return () => {
      if (refreshInterval) {
        clearInterval(refreshInterval);
      }
    };
  }, [build.state]);

  const onCustomFiltersCommitted = useCallback(
    (filters) => {
      const newExcludePartialLayers = filters.excludePartialLayers;
      if (newExcludePartialLayers !== excludePartialLayers) {
        setExcludePartialLayers(newExcludePartialLayers);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [excludePartialLayers]
  );

  return (
    <GenericTable
      filteringEnabled
      permanentFilters={defectSummaryBaseFilters}
      resourceType="defectSummary"
      useData={useDefectSummaries}
      headerFlexDirection="row-reverse"
      minTableWidth={'564px'}
      tableOptions={{
        selection: !!onSelectionChange,
        ...tableOptions,
      }}
      forcedRefresh={forcedRefresh}
      tableProps={{onSelectionChange}}
      customFilters={['excludePartialLayers']}
      onCustomFiltersCommitted={onCustomFiltersCommitted}
      mapFilterSchemaFn={(filterSchema) => {
        if (filterSchema.field === 'defectType' && filterableDefectTypes) {
          return {
            ...filterSchema,
            enumValues: filterableDefectTypes,
            enumLabels: filterableDefectTypes.reduce(
              (defectTypes, current) => ({
                [current]: getPrettyDefectType(current as DefectType),
                ...defectTypes,
              }),
              {}
            ),
          };
        }

        return filterSchema;
      }}
      tableTitle={
        <Button
          startIcon={csvRequesting ? <CircularProgress size={15} /> : <GetApp />}
          variant="outlined"
          color="primary"
          disabled={csvRequesting}
          onClick={handleCsvDownload}
          size={isXsScreen ? 'small' : 'medium'}
          fullWidth={isXsScreen}
        >
          <Typography noWrap style={{fontWeight: 600}}>
            Defect Summaries CSV
          </Typography>
        </Button>
      }
    />
  );
};
