import React from 'react';
import {Typography, Box} from '@material-ui/core';
import {Control, useWatch} from 'react-hook-form';
import {DefectType, FILTERABLE_DEFECT_TYPES} from '@common/api/models/builds/data/defects/IDefect';
import {getPrettyDefectType} from '@common/api/models/builds/data/defects/IDefect';
import {
  FilterType,
  IDefectNotificationConditions,
  Units,
} from '@common/api/models/builds/data/defects/IDefectNotifications';
import {FloatFormInput, SelectFormInput} from './NotificationFormInput';
import RemoveCondition from './RemoveCondition';

function SingleConditionForm({
  path,
  rootPath,
  control,
  errors,
  reset,
  index,
  rootIndex,
}: {
  path: string;
  rootPath: string;
  control: Control<IDefectNotificationConditions>;
  errors: any;
  reset: (newForm: IDefectNotificationConditions) => void;
  index: number;
  rootIndex: number;
}) {
  const selectedUnit: Units | undefined = useWatch({control, name: `${path}.unit`});
  const consecutiveLayers: number | undefined = useWatch({name: 'consecutiveLayers', control: control});
  const multipleLayers = !!consecutiveLayers && consecutiveLayers > 1;

  return (
    <Box width="100%" display="flex" margin="0px 8px">
      <Box width="calc(100% - 48px)">
        <Box width="100%" display="flex" marginTop="10px" alignItems="center">
          <Typography style={{width: '104px'}}>{multipleLayers ? 'Each' : 'A'} layer has</Typography>
          <Box width="calc(100% - 110px)" display="flex" alignItems="center" flexWrap="wrap">
            <SelectFormInput
              name={`${path}.filterType`}
              label="filter type"
              control={control}
              errors={errors}
              options={[
                [FilterType.INDIVIDUAL, 'An occurrence'],
                [FilterType.ACCUMULATED, 'An accumulation'],
              ]}
            />
          </Box>
        </Box>
        <Box width="100%" display="flex" marginTop="10px" alignItems="center">
          <Typography style={{width: '104px'}}>of</Typography>
          <Box width="calc(100% - 110px)" display="flex" alignItems="center" flexWrap="wrap">
            <SelectFormInput
              name={`${path}.defectType`}
              label="defect type"
              control={control}
              errors={errors}
              options={FILTERABLE_DEFECT_TYPES.map((value) => [value, getPrettyDefectType(value as DefectType)])}
            />
          </Box>
        </Box>
        <Box width="100%" display="flex" marginTop="10px" alignItems="center">
          <Typography style={{width: '104px'}}>with a</Typography>
          <Box width="calc(100% - 110px)" display="flex" alignItems="center" flexWrap="wrap">
            <SelectFormInput
              name={`${path}.unit`}
              label="area/coverage"
              control={control}
              errors={errors}
              options={[
                [Units.MM, 'Defect area (mm²)'],
                [Units.PERCENT, 'Defect coverage (%)'],
              ]}
            />
          </Box>
        </Box>

        <Box width="100%" display="flex" marginTop="10px" alignItems="center">
          <Typography style={{width: '104px'}}>over</Typography>
          <Box width="calc(100% - 110px)" display="flex" alignItems="center" flexWrap="wrap">
            <FloatFormInput
              name={`${path}.threshold`}
              label="threshold"
              control={control}
              errors={errors}
              max={selectedUnit === Units.PERCENT ? 100 : undefined}
            />
          </Box>
        </Box>
      </Box>

      <Box width="48px">
        <RemoveCondition path={rootPath} control={control} reset={reset} index={index} rootIndex={rootIndex} />
      </Box>
    </Box>
  );
}

export default SingleConditionForm;
