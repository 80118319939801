import React, {useState, useMemo} from 'react';
import {useSelector} from 'react-redux';
import {isNil, omit, omitBy} from 'lodash';
import {FilterList as FilterListIcon} from '@material-ui/icons';
import {Typography, Button, Grid, GridSize} from '@material-ui/core';
import {RootState} from '../../../store/reducers';
import FilterModal, {FilterListHeader} from './FilterModal';
import filterSchemas, {FilterSchemaType} from './filterSchemas';
import {ComparisonFilters, FiltersState, ResourceTypes} from './IndexPage';
import {useExtraSmallScreenSize} from '../../../utils/utilHooks';

interface FilterHeaderProps {
  resourceType: ResourceTypes;
  filteringEnabled: boolean;
  setFilters: React.Dispatch<React.SetStateAction<FiltersState>> | ((filters: FiltersState) => void);
  filters: FiltersState;
  tableHeader?: string | JSX.Element;
  tableTitle?: string | JSX.Element;
  tableHeaderFilters?: Array<{field: string; smGridSize: GridSize}>;
  headerFlexDirection?: 'row' | 'row-reverse';
  showFilterModal?: boolean;
  mapFilterSchemaFn?: (filterSchema: FilterSchemaType) => FilterSchemaType | undefined;
}

export const FilterHeader = ({
  resourceType,
  filteringEnabled,
  filters,
  setFilters,
  tableHeader,
  tableTitle,
  tableHeaderFilters,
  mapFilterSchemaFn = (filterSchema: FilterSchemaType) => filterSchema,
  headerFlexDirection = 'row',
  showFilterModal = true,
}: FilterHeaderProps) => {
  const isXsScreen = useExtraSmallScreenSize();
  const [isFilterModalOpen, setFilterModalOpen] = useState(false);
  const authUser = useSelector((state: RootState) => state.auth.user);
  const filterSchema = useMemo(() => filterSchemas[`${resourceType}FilterSchema`], [resourceType]);
  const currentFilters = useMemo(() => omit(filters, ['take', 'page', 'sortBy']), [filters]);
  const showClearFilters = !!Object.keys(currentFilters).length;
  const tableHeaderFiltersFields = tableHeaderFilters?.map((filter) => filter.field) || [];

  const onApplyFilters = (newFilters: ComparisonFilters | undefined) => {
    setFilterModalOpen(false);
    const {take, sortBy} = filters;
    if (newFilters) {
      setFilters({...omitBy(newFilters, isNil), take, sortBy, page: 0});
    }
  };

  const clearFilters = () => {
    const {take, sortBy} = filters;
    setFilters({take, page: 0, sortBy});
  };

  return (
    <div style={{display: 'flex', flexDirection: 'column'}}>
      {!!tableHeader && tableHeader}
      <div
        style={{
          flexDirection: headerFlexDirection,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        {!!tableTitle && (
          <Grid
            container
            item
            xs={12}
            sm={filteringEnabled ? 5 : 12}
            justifyContent={headerFlexDirection === 'row' ? 'flex-start' : 'flex-end'}
          >
            {React.isValidElement(tableTitle) ? tableTitle : <Typography variant="h5">{tableTitle}</Typography>}
          </Grid>
        )}
        {!!filteringEnabled && (
          <Grid
            container
            direction={headerFlexDirection}
            item
            xs={12}
            sm={tableTitle ? 7 : 12}
            spacing={1}
            justifyContent="flex-end"
            alignItems="center"
            wrap="nowrap"
          >
            {showClearFilters && (
              <Grid item>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={clearFilters}
                  fullWidth={isXsScreen}
                  size={isXsScreen ? 'small' : 'medium'}
                >
                  <Typography style={{fontWeight: 600}} noWrap>
                    Clear filters...
                  </Typography>
                </Button>
              </Grid>
            )}
            {showFilterModal && (
              <Grid item>
                <Button
                  variant="outlined"
                  color="primary"
                  style={{float: headerFlexDirection === 'row' ? 'right' : 'left'}}
                  onClick={() => setFilterModalOpen(true)}
                  startIcon={<FilterListIcon />}
                  fullWidth={isXsScreen}
                  size={isXsScreen ? 'small' : 'medium'}
                >
                  <Typography style={{fontWeight: 600}} noWrap>
                    Filter by...
                  </Typography>
                </Button>
              </Grid>
            )}
          </Grid>
        )}
        {!!filteringEnabled && isFilterModalOpen && (
          <FilterModal
            isOpen={isFilterModalOpen}
            onClose={() => setFilterModalOpen(false)}
            onApplyFilters={onApplyFilters}
            filterSchema={
              filterSchema
                .map(mapFilterSchemaFn)
                .filter((filter) => !!filter)
                .filter(
                  (filter) => !filter!.requiredAuth || authUser!.role >= filter!.requiredAuth
                ) as FilterSchemaType[]
            }
            currentFilters={currentFilters}
            resourceType={resourceType}
          />
        )}
      </div>
      {!!filteringEnabled && !!tableHeaderFilters && !!tableHeaderFilters.length && (
        <FilterListHeader
          filterSchema={filterSchema
            .filter((filter) => tableHeaderFiltersFields.includes(filter.field))
            .sort((a, b) => tableHeaderFiltersFields.indexOf(a.field) - tableHeaderFiltersFields.indexOf(b.field))}
          resourceType={resourceType}
          tableHeaderFilters={tableHeaderFilters}
          currentFilters={currentFilters}
          onApplyFilters={onApplyFilters}
        />
      )}
    </div>
  );
};
