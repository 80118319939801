import {SimilarityComparisonType} from '../ISimilarity';

// DefectType is used to classify the type of a specific defect.
export enum DefectType {
  ColdSpots = 'coldSpots',
  HotSpots = 'hotSpots',
  ModerateSpatter = 'moderateSpatter',
  SeriousSpatter = 'seriousSpatter',
  Lsdd = 'lsdd',
  ImageBlur = 'imageBlur',
  HatchVectors = 'hatchVectors',
  HatchAngles = 'hatchAngles',
  HatchFrequency = 'hatchFrequency',
  LatticeStruts = 'latticeStruts',
  PlanarDistortion = 'planarDistortion',
  SupportMaterial = 'supportMaterial',
  EdgeDistortion = 'edgeDistortion',
  Porosity = 'porosity',
  Unknown = 'unknown',
}

export enum DefectSeverity {
  LOW = 'Low',
  MEDIUM = 'Medium',
  HIGH = 'High',
}

export const FILTERABLE_DEFECT_TYPES = [
  DefectType.ColdSpots,
  DefectType.HotSpots,
  DefectType.ModerateSpatter,
  DefectType.SeriousSpatter,
  DefectType.Lsdd,
  DefectType.Porosity,
];

export function stringToDefectType(defectString: string): DefectType {
  switch (defectString) {
    case 'coldSpots':
    case 'cold_spots':
      return DefectType.ColdSpots;
    case 'moderateSpatter':
    case 'moderate_spatter':
      return DefectType.ModerateSpatter;
    case 'seriousSpatter':
    case 'serious_spatter':
      return DefectType.SeriousSpatter;
    case 'hotSpots':
    case 'hot_spots':
      return DefectType.HotSpots;
    case 'lsdd':
    case 'severe':
      return DefectType.Lsdd;
    case 'imageBlur':
    case 'image_blur':
      return DefectType.ImageBlur;
    case 'hatchVectors':
    case 'hatch_vectors':
      return DefectType.HatchVectors;
    case 'hatchAngles':
    case 'hatch_angles':
      return DefectType.HatchAngles;
    case 'hatchFrequency':
    case 'hatch_frequency':
      return DefectType.HatchFrequency;
    case 'latticeStruts':
    case 'lattice_struts':
      return DefectType.LatticeStruts;
    case 'planarDistortion':
    case 'planar_distortion':
      return DefectType.PlanarDistortion;
    case 'supportMaterial':
    case 'support_material':
      return DefectType.SupportMaterial;
    case 'edgeDistortion':
    case 'edge_distortion':
      return DefectType.EdgeDistortion;
    case 'porosity':
      return DefectType.Porosity;
    default:
      return DefectType.Unknown;
  }
}

export function getPrettyDefectType(defectType: DefectType | SimilarityComparisonType): string {
  return {
    [DefectType.ColdSpots]: 'Cold spot',
    [DefectType.HotSpots]: 'Hot spot',
    [DefectType.ModerateSpatter]: 'Moderate spatter',
    [DefectType.SeriousSpatter]: 'Serious spatter',
    [DefectType.Lsdd]: 'Severe defect',
    [DefectType.ImageBlur]: 'Image blur',
    [DefectType.HatchVectors]: 'Hatch vectors',
    [DefectType.HatchAngles]: 'Hatch angles',
    [DefectType.HatchFrequency]: 'Hatch frequency',
    [DefectType.LatticeStruts]: 'Lattice struts',
    [DefectType.PlanarDistortion]: 'Planar distortion',
    [DefectType.SupportMaterial]: 'Support material',
    [DefectType.EdgeDistortion]: 'Edge distortion',
    [DefectType.Porosity]: 'Porosity',
    [DefectType.Unknown]: 'Unknown type',
    [SimilarityComparisonType.intensity]: 'ASIM',
    [SimilarityComparisonType.hotSpots]: 'Hot spot',
    [SimilarityComparisonType.coldSpots]: 'Cold spot',
    [SimilarityComparisonType.moderateSpatter]: 'Moderate spatter',
    [SimilarityComparisonType.seriousSpatter]: 'Serious spatter',
    [SimilarityComparisonType.lsdd]: 'Severe defect',
  }[defectType];
}

export function getDefectSeverity(defectType: DefectType): DefectSeverity {
  return {
    [DefectType.ColdSpots]: DefectSeverity.LOW,
    [DefectType.HotSpots]: DefectSeverity.MEDIUM,
    [DefectType.ModerateSpatter]: DefectSeverity.LOW,
    [DefectType.SeriousSpatter]: DefectSeverity.HIGH,
    [DefectType.Lsdd]: DefectSeverity.HIGH,
    [DefectType.ImageBlur]: DefectSeverity.LOW,
    [DefectType.HatchVectors]: DefectSeverity.LOW,
    [DefectType.HatchAngles]: DefectSeverity.LOW,
    [DefectType.HatchFrequency]: DefectSeverity.LOW,
    [DefectType.LatticeStruts]: DefectSeverity.LOW,
    [DefectType.PlanarDistortion]: DefectSeverity.LOW,
    [DefectType.SupportMaterial]: DefectSeverity.LOW,
    [DefectType.EdgeDistortion]: DefectSeverity.MEDIUM,
    [DefectType.Porosity]: DefectSeverity.LOW,
    [DefectType.Unknown]: DefectSeverity.MEDIUM,
  }[defectType];
}

export enum DefectsTableColumns {
  DefectType = 'defectType',
  DefectSeverity = 'severity',
  Timestamp = 'timestamp',
  PartName = 'partName',
  Layer = 'layerNum',
  Area = 'area',
  Coverage = 'coverage',
}

export const severityToDefectTypes = (defectSeverity: DefectSeverity): DefectType[] => {
  return {
    [DefectSeverity.LOW]: [
      DefectType.ColdSpots,
      DefectType.ModerateSpatter,
      DefectType.Porosity,
      DefectType.ImageBlur,
      DefectType.HatchVectors,
      DefectType.HatchAngles,
      DefectType.HatchFrequency,
      DefectType.LatticeStruts,
      DefectType.PlanarDistortion,
      DefectType.SupportMaterial,
    ],
    [DefectSeverity.MEDIUM]: [DefectType.HotSpots, DefectType.EdgeDistortion],
    [DefectSeverity.HIGH]: [DefectType.SeriousSpatter, DefectType.Lsdd],
  }[defectSeverity];
};

// AnalysisType2D describes a type of layer displayed on the 2D viewport.
// Each DefectType is displayed on some AnalysisType, but an AnalysisType may contain multiple DefectTypes.
export enum AnalysisType2D {
  Layer = 'layer',
  LayerMask = 'layerMask',
  ColourMap = 'colourMap',
  Lsdd = 'lsdd',
  EdgeDistortion = 'edgeDistortion',
  RecoaterStreaks = 'recoaterStreaks',
  Spatter = 'spatter',
  HotCold = 'hotcold',
  Porosity = 'porosity',
  ImageBlur = 'imageBlur',
  HatchVectors = 'hatchVectors',
  HatchAngles = 'hatchAngles',
  HatchFrequency = 'hatchFrequency',
  LatticeStruts = 'latticeStruts',
  PlanarDistortion = 'planarDistortion',
  SupportMaterial = 'supportMaterial',
}

export const getDefectAnalysisType = (defectType: DefectType) =>
  ({
    [DefectType.ColdSpots]: AnalysisType2D.HotCold,
    [DefectType.HotSpots]: AnalysisType2D.HotCold,
    [DefectType.ModerateSpatter]: AnalysisType2D.Spatter,
    [DefectType.SeriousSpatter]: AnalysisType2D.Spatter,
    [DefectType.Lsdd]: AnalysisType2D.Lsdd,
    [DefectType.ImageBlur]: AnalysisType2D.ImageBlur,
    [DefectType.HatchVectors]: AnalysisType2D.HatchVectors,
    [DefectType.HatchAngles]: AnalysisType2D.HatchAngles,
    [DefectType.HatchFrequency]: AnalysisType2D.HatchFrequency,
    [DefectType.LatticeStruts]: AnalysisType2D.LatticeStruts,
    [DefectType.PlanarDistortion]: AnalysisType2D.PlanarDistortion,
    [DefectType.SupportMaterial]: AnalysisType2D.SupportMaterial,
    [DefectType.EdgeDistortion]: AnalysisType2D.EdgeDistortion,
    [DefectType.Porosity]: AnalysisType2D.Porosity,
    [DefectType.Unknown]: AnalysisType2D.HotCold,
  }[defectType]);

export const getPrettyAnalysisType2D = (analysisType: AnalysisType2D) =>
  ({
    [AnalysisType2D.Layer]: 'Raw',
    [AnalysisType2D.LayerMask]: 'Layer mask',
    [AnalysisType2D.ColourMap]: 'Colour map',
    [AnalysisType2D.Lsdd]: 'Severe defect',
    [AnalysisType2D.EdgeDistortion]: 'Edge distortion',
    [AnalysisType2D.RecoaterStreaks]: 'Recoater streaks',
    [AnalysisType2D.Spatter]: 'Spatter',
    [AnalysisType2D.HotCold]: 'Hot/cold spots',
    [AnalysisType2D.Porosity]: 'Porosity',
    [AnalysisType2D.ImageBlur]: 'Image blur',
    [AnalysisType2D.HatchVectors]: 'Hatch vectors',
    [AnalysisType2D.HatchAngles]: 'Hatch angles',
    [AnalysisType2D.HatchFrequency]: 'Hatch frequency',
    [AnalysisType2D.LatticeStruts]: 'Lattice struts',
    [AnalysisType2D.PlanarDistortion]: 'Planar distortion',
    [AnalysisType2D.SupportMaterial]: 'Support material',
  }[analysisType]);

// AnalysisType3D describes a type of point-cloud displayed on the 3D-viewport.
export enum AnalysisType3D {
  // Model represents the main model of the part (Cube segments for similarity, Part Model for part viewport)
  Model = 'model',
  // Geometry represents the part geoemtry (part point cloud), only used in similarity to give a feel for the parts geometry
  Geometry = 'geometry',
  Lsdd = 'lsdd',
  EdgeDistortion = 'edgeDistortion',
  ColdSpots = 'coldSpots',
  HotSpots = 'hotSpots',
  ModerateSpatter = 'moderateSpatter',
  SeriousSpatter = 'seriousSpatter',
  Porosity = 'porosity',
  ASIM = 'asim',
  // Pores detected from the CT Reports
  Pore = 'pore',
  // CTModel represents Point Clouds that were Generated For CT Reports
  CTModel = 'ctModel',
  // PartModel STL file uploaded by the user. Better 3D model representation of the part
  PartModel = 'partModel',
}

export enum CTDefectType3D {
  Volume = 'volume',
  SurfaceArea = 'surfaceArea',
  SurfacePerVolume = 'surfacePerVolume',
  Extent = 'extent',
  Solidity = 'solidity',
}

export const DEFECT_POINT_CLOUD_TYPES = [
  AnalysisType3D.Lsdd,
  AnalysisType3D.EdgeDistortion,
  AnalysisType3D.ColdSpots,
  AnalysisType3D.HotSpots,
  AnalysisType3D.ModerateSpatter,
  AnalysisType3D.SeriousSpatter,
  AnalysisType3D.Porosity,
];

export const comparisonTypeToAnalysisType3D: (comparisonType: number) => AnalysisType3D = (comparisonType: number) => {
  switch (comparisonType) {
    case 0:
      return AnalysisType3D.ASIM;
    case 1:
      return AnalysisType3D.HotSpots;
    case 2:
      return AnalysisType3D.ColdSpots;
    case 3:
      return AnalysisType3D.Lsdd;
    case 4:
      return AnalysisType3D.ModerateSpatter;
    case 5:
      return AnalysisType3D.SeriousSpatter;
  }
};

export const getPrettyAnalysisType3D = (analysisType: AnalysisType3D) =>
  ({
    [AnalysisType3D.Model]: 'Model',
    [AnalysisType3D.Geometry]: 'Geometry',
    [AnalysisType3D.Lsdd]: 'Severe defects',
    [AnalysisType3D.EdgeDistortion]: 'Edge distortion',
    [AnalysisType3D.ModerateSpatter]: 'Moderate spatter',
    [AnalysisType3D.SeriousSpatter]: 'Serious spatter',
    [AnalysisType3D.HotSpots]: 'Hot spots',
    [AnalysisType3D.ColdSpots]: 'Cold spots',
    [AnalysisType3D.Porosity]: 'Porosity',
    [AnalysisType3D.ASIM]: 'ASIM',
    [AnalysisType3D.Pore]: 'CT Pores',
    [AnalysisType3D.CTModel]: 'CT Model',
    [AnalysisType3D.PartModel]: 'Part model',
  }[analysisType]);

// NOTE: These colours are derived from the colour map in AA_Analysis. See porosity_utils.py for more information.
export const POROSITY_COLOR_MAPPINGS = {
  HHH: [127, 0, 0],
  '-HH': [227, 0, 0],
  'H-H': [255, 52, 0],
  CHH: [255, 81, 0],
  'HH-': [255, 163, 0],
  HCH: [255, 192, 0],
  'C-H': [255, 218, 0],
  '-CH': [221, 255, 24],
  'CH-': [173, 255, 73],
  CCH: [147, 255, 99],
  HHC: [99, 255, 147],
  'HC-': [73, 255, 173],
  '-HC': [24, 255, 221],
  'H-C': [0, 200, 255],
  CHC: [0, 172, 255],
  'CC-': [0, 140, 255],
  HCC: [0, 52, 255],
  'C-C': [0, 20, 255],
  '-CC': [0, 0, 227],
  CCC: [0, 0, 127],
};
