import {
  DefectSeverity,
  FILTERABLE_DEFECT_TYPES,
  getPrettyDefectType,
  POROSITY_COLOR_MAPPINGS,
} from '@common/api/models/builds/data/defects/IDefect';
import {FilterSchemaType} from './index';

export const defectStatisticDefaultFilters = {
  sortBy: {layerNum: 'DESC', defectArea: 'DESC'},
};

const defectStatisticFilters: Array<FilterSchemaType> = [
  {
    field: 'defectType',
    label: 'Defect Type',
    type: 'enumMultiple',
    enumValues: FILTERABLE_DEFECT_TYPES,
    enumLabels: FILTERABLE_DEFECT_TYPES.reduce(
      (defectTypes, current) => ({
        [current]: getPrettyDefectType(current),
        ...defectTypes,
      }),
      {}
    ),
  },
  {
    field: 'subDefectType',
    label: 'Sub Defect Type',
    type: 'enumMultiple',
    enumValues: Object.keys(POROSITY_COLOR_MAPPINGS),
  },

  {
    field: 'severity',
    type: 'enumMultiple',
    enumValues: Object.values(DefectSeverity),
  },
  {
    field: 'partName',
    label: 'Part Name',
    type: 'string',
  },
  {
    field: 'layerNum',
    label: 'Layer',
    type: 'int',
    min: 0,
  },
  {
    field: 'defectArea',
    label: 'Area (mm²)',
    type: 'float',
    min: 0,
  },
  {
    field: 'defectCoverage',
    label: 'Coverage (%)',
    type: 'float',
    min: 0,
    max: 100,
  },
  {
    field: 'excludePartialLayers',
    label: 'Exclude Partial Layers',
    type: 'boolean',
  },
];

export default defectStatisticFilters;
