import React, {useEffect, useState} from 'react';
import {Box, Grid, Typography, IconButton} from '@material-ui/core';
import {Skeleton} from '@material-ui/lab';
import {useSelector} from 'react-redux';
import {Edit} from '@material-ui/icons';

import {Role} from '@common/api/models/users/IUser';
import {IBuild, IProvisionerGETResponse} from '@common/api/models/builds/IBuild';

import {RootState} from '../../../store/reducers';
import ChangeBuildProvisionerModal from '../../../components/molecules/Modals/ChangeBuildProvisionerModal';
import {buildsProvisionerGET} from '../../../api/ajax/builds';

const ProvisionerDetails = ({build}: {build: IBuild}) => {
  const [provisioner, setProvisioner] = useState<IProvisionerGETResponse>();

  const [changeProvisionerOpen, setChangeProvisionerOpen] = useState<boolean>(false);
  const currentUser = useSelector((state: RootState) => state.auth.user!);

  useEffect(() => {
    const fetchProvisionerDetails = async () => {
      const res = await buildsProvisionerGET(build.uuid);
      if (res.success) {
        setProvisioner(res.data);
      }
    };

    if (build?.provisionerUuid) {
      setProvisioner(undefined);
      fetchProvisionerDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [build?.provisionerUuid]);

  return (
    <>
      {provisioner && (
        <ChangeBuildProvisionerModal
          open={changeProvisionerOpen}
          closeDialog={() => setChangeProvisionerOpen(false)}
          buildUuid={build.uuid}
          provisioner={provisioner}
        />
      )}
      <Grid item xs={12}>
        <Typography variant={'h6'}>Build Provisioner</Typography>
      </Grid>
      <Grid item xs={12}>
        {provisioner ? (
          <>
            <Box display="flex" alignItems="center">
              <Typography>{`${provisioner.firstName} ${provisioner.lastName}`}</Typography>
              {(currentUser.role >= Role.MANAGER || currentUser.uuid === build.provisionerUuid) && (
                <IconButton aria-label="edit" onClick={() => setChangeProvisionerOpen(true)}>
                  <Edit />
                </IconButton>
              )}
            </Box>
          </>
        ) : (
          <Skeleton />
        )}
      </Grid>
    </>
  );
};

export default ProvisionerDetails;
