import {FILTERABLE_DEFECT_TYPES, getPrettyDefectType} from '@common/api/models/builds/data/defects/IDefect';
import {FilterSchemaType} from './index';

const defectSummaryFilters: Array<FilterSchemaType> = [
  {
    field: 'defectType',
    label: 'Defect Type',
    type: 'enumMultiple',
    enumValues: FILTERABLE_DEFECT_TYPES,
    enumLabels: FILTERABLE_DEFECT_TYPES.reduce(
      (defectTypes, current) => ({
        [current]: getPrettyDefectType(current),
        ...defectTypes,
      }),
      {}
    ),
  },
  {
    field: 'partName',
    label: 'Part Name',
    type: 'string',
  },
  {
    field: 'defectArea',
    label: 'Area (mm²)',
    type: 'float',
    min: 0,
  },
  {
    field: 'defectCoverage',
    label: 'Coverage (%)',
    type: 'float',
    min: 0,
    max: 100,
    transformValue: (direction, value) => {
      if (direction === 'in') {
        return value * 100;
      } else {
        return value / 100;
      }
    },
  },
  {
    field: 'excludePartialLayers',
    label: 'Exclude Partial Layers',
    type: 'boolean',
  },
];

export default defectSummaryFilters;
