import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {FormControlLabel, Checkbox} from '@material-ui/core';
import {Role} from '@common/api/models/users/IUser';
import {IProvisionerGETResponse} from '@common/api/models/builds/IBuild';
import {RootState} from '../../../store/reducers';
import {GenericDialog} from '../DialogButton';
import SearchAndSelectUser from '../Selector/SearchAndSelectUser';
import {SelectedUserType} from '../Selector/SearchAndSelectUser';
import ConditionalTooltip from '../../atoms/Texts/ConditionalTooltip';
import {buildsByUuidPATCH} from '../../../api/ajax/builds';

const ChangeBuildProvisionerModal = ({
  open,
  closeDialog,
  buildUuid,
  provisioner,
}: {
  open: boolean;
  closeDialog: () => void;
  buildUuid: string;
  provisioner: IProvisionerGETResponse;
}) => {
  const history = useHistory();
  const currentUser = useSelector((state: RootState) => state.auth.user!);

  const [requesting, setRequesting] = useState<boolean>(false);
  const [newProvisioner, setNewProvisioner] = useState<SelectedUserType | undefined>();
  const [revokePermission, setRevokePermission] = useState<boolean>(false);

  const onChangeCommitted = async () => {
    setRequesting(true);
    const shouldRedirect = revokePermission && provisioner.uuid === currentUser.uuid && currentUser.role < Role.MANAGER;

    const res = await buildsByUuidPATCH(buildUuid, {
      provisionerUuid: newProvisioner!.uuid,
      revokeProvisionerPermissions: revokePermission,
    } as any);

    setRequesting(false);
    setNewProvisioner(undefined);

    if (res.success) {
      if (shouldRedirect) {
        history.push('/builds');
      } else {
        closeDialog();
      }
    }
  };

  return (
    <GenericDialog
      title="Change Build Provisioner"
      isOpen={open}
      closeDialog={closeDialog}
      closeText="Close"
      confirmText={revokePermission ? 'Save & Revoke' : 'Save'}
      onSuccess={onChangeCommitted}
      requestInProgress={requesting}
      confirmDisabled={!newProvisioner}
      content={
        <>
          <SearchAndSelectUser
            selectedUser={newProvisioner}
            setSelectedUser={setNewProvisioner}
            filterOutUuids={[provisioner.uuid]}
          />
          <ConditionalTooltip
            tooltip="Can't revoke permissions for Managers and above"
            hideTooltip={provisioner.role < Role.MANAGER}
          >
            <FormControlLabel
              label="Revoke current provisioners access?"
              disabled={provisioner.role >= Role.MANAGER}
              control={
                <Checkbox checked={revokePermission} onChange={(_e, _v) => setRevokePermission(!revokePermission)} />
              }
            />
          </ConditionalTooltip>
        </>
      }
    />
  );
};

export default ChangeBuildProvisionerModal;
